import AOS from 'aos';
import Modal from 'react-modal';

import 'typeface-montserrat';
import 'typeface-roboto';

import 'prismjs/themes/prism.css';
import 'remixicon/fonts/remixicon.css';

import 'aos/dist/aos.css';
import './src/pages/tailwind.css';

AOS.init();
Modal.setAppElement('body');

window.onscroll = () => {
  if (document.documentElement.scrollTop > 70) {
    document.body.classList.add('shrunk-header');
  } else {
    document.body.classList.remove('shrunk-header');
  }
};
